var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"text-center"},[_c('v-dialog',{attrs:{"width":"500"},scopedSlots:_vm._u([(
				_vm._usrFlagsSome({
					key: 'bonus.groups.detail',
					val: _vm.permissions.EDIT,
				})
			)?{key:"activator",fn:function({ on, attrs }){return [_c('v-btn',_vm._g(_vm._b({attrs:{"color":_vm.color,"elevation":"1","fab":"","small":""},on:{"click":_vm.repopulateForm}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-pencil")])],1)]}}:null],null,true),model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[_c('v-card',[_c('v-card-title',{staticClass:"headline primary"},[_vm._v("Edit Group")]),_c('v-card-text',{staticClass:"pa-8"},[_c('v-form',{ref:"form",on:{"submit":_vm.submit},model:{value:(_vm.valid),callback:function ($$v) {_vm.valid=$$v},expression:"valid"}},[_c('v-text-field',{attrs:{"label":"Group Name","rules":[
							_vm.rules.required,
							_vm.rules.no_spaces,
							_vm.rules.minMaxLength(1, 32),
						],"type":"text"},model:{value:(_vm.groupName),callback:function ($$v) {_vm.groupName=$$v},expression:"groupName"}}),_c('br'),_c('v-divider'),_c('br'),_c('v-row',[_c('v-col',{staticClass:"col-6 py-4 justify-center align-center text-center"},[_c('span',[_vm._v("Current Group Config ID: "+_vm._s(_vm.groupConfigID))])]),(_vm.list)?_c('v-select',{staticClass:"col-6",attrs:{"items":_vm.list,"item-value":"id","item-text":"id","label":"Select Group Config","rules":[_vm.rules.required],"return-object":""},model:{value:(_vm.selectedGroupConfig),callback:function ($$v) {_vm.selectedGroupConfig=$$v},expression:"selectedGroupConfig"}}):_c('div',[_vm._v("Group config list error")])],1),_c('fieldset',{staticClass:"pa-3"},[_c('legend',{staticClass:"body-1 px-2"},[_vm._v("Config Preview")]),_c('v-text-field',{attrs:{"label":"Contribution","suffix":_vm.contribTypeId === 1 ? '%' : '',"disabled":""},model:{value:(_vm.contrib),callback:function ($$v) {_vm.contrib=$$v},expression:"contrib"}}),_c('v-text-field',{attrs:{"label":"Selected Contribution Type","disabled":""},model:{value:(_vm.contribType.contribType),callback:function ($$v) {_vm.$set(_vm.contribType, "contribType", $$v)},expression:"contribType.contribType"}}),_c('v-text-field',{attrs:{"label":"Currency","items":_vm.currencyCodes,"disabled":""},model:{value:(_vm.currency),callback:function ($$v) {_vm.currency=$$v},expression:"currency"}})],1)],1)],1),_c('v-divider'),_c('v-card-actions',[_c('v-btn',{attrs:{"text":""},on:{"click":function($event){_vm.dialog = false}}},[_vm._v(" Cancel ")]),_c('v-spacer'),_c('v-btn',{attrs:{"color":"primary","text":"","disabled":_vm.createdJackpotGroup.isLoading},on:{"click":_vm.submit}},[_vm._v(" Submit ")])],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }